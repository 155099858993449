<script setup>
import AttackerStats from "@/components/OneVsOne/ProfileStats/AttackerStats"
import BtnAddProfile from "@/components/Common/BtnAddProfile"
import { useGlobalStore } from "@/stores/global"
import EntitySelector from "@/components/Common/EntitySelector.vue"
import UcBtn from "@/components/UC/UcBtn.vue"
import UcAlert from "@/components/UC/UcAlert.vue"
import { computed, defineAsyncComponent, ref } from "vue"
import { useEditProfileStore } from "@/stores/editProfile"

const DialogCommon = defineAsyncComponent(
  () => import("@/components/Common/DialogCommon.vue")
)
const EditProfile = defineAsyncComponent(
  () => import("@/components/EditProfile/Profile/EditProfile.vue")
)

const storeEditProfile = useEditProfileStore()
const store = useGlobalStore()

const showDialogAddProfile = ref(false)
const showDialogEditProfile = ref(false)

const addProfileBtnRole = computed(() => {
  return `Attacker: ${store.attackContext.attackType}`
})

const attacker = computed({
  get() {
    if (store.currentAttackerCompatibleWithAttackType) {
      return store.currentAttacker
    } else {
      return {}
    }
  },
  set(obj) {
    store.setCurrentAttacker(obj)
  },
})

const processedAttackers = computed(() => {
  return store.profilesProcessedAttackers ?? []
})

const attackTypeLowerCase = computed(() => {
  return store.attackContext.attackType.toLowerCase()
})

function editProfile() {
  storeEditProfile.limitProfileFormAccess = true
  storeEditProfile.intendedRole = `Attacker: ${store.attackContext.attackType}`
  showDialogEditProfile.value = true
}

function setCurrentProfileId() {
  if (typeof attacker.value.id !== "undefined") {
    return attacker.value.id
  }
}
</script>

<template>
  <div>
    <UcAlert v-if="!processedAttackers.length" class="mb-4">
      You don't have any profiles that meet the requirements for
      {{ attackTypeLowerCase }} attacks. Update an existing profile or create a
      new one.
    </UcAlert>

    <div class="buttons">
      <EntitySelector
        v-if="processedAttackers.length"
        v-model="attacker"
        label="Saved profiles"
        :items="processedAttackers"
        item-text="name"
        :disabled="store.readOnlyProfiles"
        filter-input-label="Select attacker profile"
        data-cy="input-select-attacker"
      />
      <BtnAddProfile
        :role="addProfileBtnRole"
        data-cy="btn-add-attacker"
        size="small"
        @click="showDialogAddProfile = true"
      />
      <UcBtn
        v-if="!processedAttackers.length"
        to="/manage-profiles"
        size="small"
      >
        Manage profiles
      </UcBtn>
      <UcBtn
        v-if="attacker.name && store.currentAttackerCompatibleWithAttackType"
        data-cy="btn-edit-attacker"
        :disabled="store.readOnlyProfiles"
        size="small"
        @click="editProfile()"
      >
        Edit profile
      </UcBtn>
    </div>

    <AttackerStats
      v-if="attacker.name && store.currentAttackerCompatibleWithAttackType"
      :key="String(`${attacker.id}--${attacker.updated}`)"
    />

    <DialogCommon v-if="showDialogAddProfile" v-model="showDialogAddProfile">
      <EditProfile
        set-as-current="Attacker"
        @close="showDialogAddProfile = false"
      />
    </DialogCommon>

    <DialogCommon v-if="showDialogEditProfile" v-model="showDialogEditProfile">
      <EditProfile
        :profile-id="setCurrentProfileId()"
        set-as-current="Attacker"
        @close="showDialogEditProfile = false"
      />
    </DialogCommon>
  </div>
</template>

<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
